var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c(
        "div",
        { staticClass: "back", on: { click: _vm.goBack } },
        [
          _c("svgicon", {
            staticClass: "triangle",
            attrs: { name: "triangle" },
          }),
          _c("label", [_vm._v("BACK")]),
          _c("span", { staticClass: "spacer" }, [_vm._v("/")]),
          _vm._l(_vm.labels, function (label, index) {
            return _c("span", { key: `${label}-${index}` }, [
              index > 0
                ? _c("span", { staticClass: "spacer" }, [_vm._v("/")])
                : _vm._e(),
              _vm._v(_vm._s(label) + " "),
            ])
          }),
        ],
        2
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }